<template>
  <div id="app">
  <div id="connectHolder" display="none">
                    <div id="connectButton" class="border_red">
                        <div class="connectInner"><span id="connectText" class="el-st">CONNECT</span></div>
                    </div>
                </div>
                <div id="inputHolder">
                    <div id="internalWrapper">
                        <div id="inputWrapper">
                            <div id="menuInputs" display="none">
                                <div class="socialsholder"><a class="linkCursor" href="https://t.me/defcafesociety"><img class="pixelated hoverimage socialsimage" src="assets/img/tg-pc.png"><img class="pixelated hoverimage socialsimagem" src="assets/img/tg-m.png"></a><a class="linkCursor" href="https://docs.def.cafe/presale-sniper/warning"><img class="pixelated hoverimage socialsimage" src="assets/img/q-pc.png"><img class="pixelated hoverimage socialsimagem" src="assets/img/q-m.png"></a></div>
                                <div class="border_red inputInner">
                                    <div class="fieldsHolder">
                                        <div class="selectableInput">
                                            <div class="selectableText"><img class="pixelated selectionArrow" src="assets/img/selection_arrow.png"><span class="el-st2 inputLabel">ADDRESS:&nbsp;</span></div><input type="text" class="input"  v-model="address">
                                        </div>
                                        <div class="selectableInput">
                                            <div class="selectableText"><img class="pixelated selectionArrow" src="assets/img/selection_arrow.png"><span class="el-st3 inputLabel">BNB:&nbsp;</span></div><input type="text" class="input"  v-model="amount">
                                        </div>
                                        <div class="selectableInput">
                                            <div class="selectableText"><img class="pixelated selectionArrow" src="assets/img/selection_arrow.png"><span class="el-st5 inputLabel">GAS:&nbsp;</span></div><input type="text" class="input"  v-model="gas">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="menuSettings" class="settings" display="none">
                                <div class="border_red inputInner">
                                    <div class="fieldsHolder" style="justify-content: center;">
                                        <div id="walletManagerInput" class="selectableInput selectableInputSettings" @click="switchLogSection('wallets')">
                                            <div class="selectableText"><img class="pixelated selectionArrow" src="assets/img/selection_arrow.png"><span class="el-st6 inputLabel">WALLETS</span></div>
                                        </div>
                                        <div id="settingsManagerInput" class="selectableInput selectableInputSettings"  @click="switchLogSection('settings')">
                                            <div class="selectableText"><img class="pixelated selectionArrow" src="assets/img/selection_arrow.png"><span class="el-st7 inputLabel">settings</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="skullHolder">
                              <img v-if="!computedBotOn" class="pixelated skull" src="assets/img/skull-start.png" @click="start()">
                              <img v-else class="pixelated skull" src="assets/img/skull-stop.png" @click="stop()">
                              <img v-if="!computedBotOn" class="pixelated skull-mini" src="assets/img/skull-mini.png" @click="start()">
                              <img v-else class="pixelated skull-mini" src="assets/img/skull-mini.png" @click="stop()">
                              <div v-if="!computedBotOn" class="border_red inputInner startWrapper" @click="start()">
                                <span class="el-st6 startLabel" >START</span>
                              </div>
                              <div v-else class="border_red inputInner startWrapper" @click="stop()">
                                <span class="el-st6 startLabel" >STOP</span>
                              </div>
                            </div>
                        </div>
                        <div id="flagHolder" class="flagHolder">
                            <div id="flagWrapper">
                                <div id="flag-right" class="pixelated"></div>
                                <transition name="fade1">
                                  <img v-if="showStars[0]" class="stars1" src="assets/img/s1-1.png" :key="showStarsReRender1">
                                </transition>
                                <transition name="fade2">
                                  <img v-if="showStars[1]" class="stars1" src="assets/img/s1-2.png" :key="showStarsReRender2">
                                </transition>
                                <transition name="fade3">
                                  <img v-if="showStars[2]" class="stars2" src="assets/img/s2-1.png" :key="showStarsReRender3">
                                </transition>
                                <transition name="fade4">
                                  <img v-if="showStars[3]" class="stars2" src="assets/img/s2-2.png" :key="showStarsReRender4">
                                </transition>
                                <transition name="fade5">
                                  <img v-if="showStars[4]" class="stars2" src="assets/img/s2-3.png" :key="showStarsReRender5">
                                </transition>
                                <span id="poweredLabel" class="el-st8">POWERED BY DEF.CAFE</span>
                            </div>
                        </div>
                        <div id="consoleHolder">
                            <div class="consoleWrapper">
                                <div class="consoleWrapper2" display="none">
                                    <div class="border_red consoleWrapper3">
                                        <div v-if="showLogSection('popup')" id="popup" class="logs popup">
                                            <div class="popup-holder pixelated">
                                                <p class="centered-text">CONFIRM ACTION</p>
                                                <p class="warning centered-text">YOU ARE ABOUT TO DELETE YOUR WALLET. ARE YOU SURE YOU WANT TO PROCEED?</p>
                                                <p class="centered-text">
                                                  <span>
                                                    <span id="popupYes" class="backLogs" @click="deleteWallet()">&nbsp;YES&nbsp;</span>
                                                    <span id="popupNo" class="backLogs backLogsMargin" @click="cancelDeleteWallet()">&nbsp;NO&nbsp;</span>
                                                  </span>
                                                </p>
                                            </div><span class="currentBlock"></span>
                                            <p class="el-st9"><br></p>
                                        </div>
                                        <div v-if="showLogSection('logs')" id="logsMain" class="logs">
                                            <span v-if="!computedShowBlockWarning" class="currentBlock">CURRENT BLOCK: {{block}}</span>
                                            <span v-if="computedShowBlockWarning" class="currentBlock">{{blockWarningMessage}}</span>
                                            <p v-if="computedMaintenance" class="error">Servers is on maintenance. ETA 5-6 hours.</p>
                                            <p class="error">Presale sniper is on maintenance.</p>
                                            <p v-for="(item,index) in computedLogs" :key="item" v-bind:class=logsClass[index]>
                                              {{ item }}
                                              <a v-if="computedTxHash[index]" :href="'https://bscscan.com/tx/' + computedTxHash[index]" target="blank" class="warning">[bscscan]</a>
                                              <a v-if="computedTxHash[index]" @click="copyTxHash(computedTxHash[index])" class="warning"> [copy]</a>
                                            </p>
                                        </div>
                                        <div v-if="showLogSection('wallets')" id="logsWalletManager" class="logs logsSettingsClass">
                                            <span id="walletWarning" class="currentBlock">{{walletWarningMessage}}</span>
                                            <p class="el-st9 backButton" style="display: flex;justify-content: space-between;">
                                              <span id="backLogsSettingsBack" class="selectableInput selectableSpan" style="color: var(--sb-font-color-red);" @click="switchLogSection('logs')">
                                                <span class="selectableText selectableSpan">
                                                  <img class="pixelated selectionArrow selectionArrowSpan" src="assets/img/selection_arrow.png">
                                                  <img class="arrowRed pixelated selectionArrow selectionArrowSpan" src="assets/img/selection_arrow_red.png">
                                                  <span class="el-st3 inputLabel selectableSpan selectableSpanText">BACK&nbsp;</span>
                                                </span>
                                              </span>
                                              <span>
                                                <span v-if="computedShowCreateButton" id="backLogsSettingsCreate" class="backLogs" @click="createWallet()">&nbsp;CREATE&nbsp;</span>
                                                <span v-if="computedShowImportButton" id="backLogsSettingsImport" v-bind:class="[defaultButtonWalletImportClass, computedShowImportWallet ? activeWalletClass : '']" @click="showImportField()">&nbsp;IMPORT&nbsp;</span>
                                                <span v-if="walletsArray.length" id="showDeleted" class="backLogs backLogsMargin showDeletedButton" @click="switchWalletView()">&nbsp;<i v-bind:class="[computedShowTrashClass ? defaultTrashClass : activeTrashClass]"></i>&nbsp;</span>
                                              </span>
                                            </p>
                                            <p v-if="computedShowImportWallet" class="el-st9 backButton">
                                              <span class="el-st3 inputLabel selectableSpan selectableSpanText walletPKImport">PRIVATE KEY:&nbsp;</span>
                                              <span class="walletInputHolder"><input v-model="inputImportKey" type="password" class="input inputWallet">
                                                <span id="backLogsSettingsOk" class="backLogs" @click="importWallet()">&nbsp;OK&nbsp;</span>
                                              </span>
                                            </p>
                                            <p v-if="walletsArray.length" class="walletRowP">
                                              <span class="walletSpanAddress">address</span>
                                              <span class="walletSpanBNB">BNB</span>
                                              <span v-bind:class="['walletSpanUse', !computedShowTrashClass?'hidden':'']">use</span>
                                              <span class="walletSpanExport">EXPORT</span>
                                            </p>
                                            <p v-if="showEmpty()" class="walletRowP">WALLET LIST IS EMPTY.</p>
                                            <p v-for="(item, index) in computedWallets" :key="item" v-bind:class="['walletRowP', (computedShowTrashClass&&item.deleted)||(!computedShowTrashClass&&!item.deleted)?'hidden':'']">
                                              <span v-if="showWalletRow(index)" class="walletSpanAddress"  @click="copyAddress(item.address)">{{ item.address }}</span>
                                              <span v-if="showWalletRow(index)" class="walletSpanBNB" :key="reRender">{{ item.balance }}</span>
                                              <span v-if="showWalletRow(index)" v-bind:class="[defaultButtonWalletClass, item.used ? activeWalletClass : '', !computedShowTrashClass?'hidden':'']" @click="updateWalletUse(index)" :key="reRenderUse">&nbsp;</span>
                                              <span v-if="showWalletRow(index)" class="backLogs walletExportButton" @click="copyKey(item.privateKey)">
                                                <i class="far fa-copy exportIcon"></i>
                                              </span>
                                              <span v-if="showWalletRow(index)" v-bind:class="['backLogs walletExportButton closeHolder', !computedShowTrashClass?'hidden':'']" @click="confirmDeleteWallet(index)">
                                                <img class="exportIcon" src="assets/img/cross.png">
                                              </span>
                                            </p>
                                        </div>
                                        <div v-if="showLogSection('settings')" id="logsSettings" class="logs logsSettingsClass">
                                            <span id="walletWarningSettings" class="currentBlock">{{warningSettingsMessage}}</span>
                                            <p class="el-st9 backButton" style="display: flex;justify-content: space-between;">
                                              <span id="backLogsSettingsBackFrom" class="selectableInput selectableSpan" style="color: var(--sb-font-color-red);" @click="switchLogSection('logs')">
                                                <span class="selectableText selectableSpan">
                                                  <img class="pixelated selectionArrow selectionArrowSpan" src="assets/img/selection_arrow.png">
                                                  <img class="arrowRed pixelated selectionArrow selectionArrowSpan arrowRed" src="assets/img/selection_arrow_red.png">
                                                  <span class="el-st3 inputLabel selectableSpan selectableSpanText">BACK&nbsp;</span>
                                                </span>
                                              </span>
                                            </p>
                                            <span v-if="sniperSettings.presales != null">
                                              <p class="el-st9 backButton">
                                                <span class="el-st3 inputLabel selectableSpan selectableSpanText walletPKImport">Sniping mode:&nbsp;</span>
                                                <span class="walletInputHolder">
                                                  <span id="backLogsPresales" v-bind:class="[defaultSettingsSelectableButtonClass, classMarginLeft, sniperSettings.presales ? activeWalletClass : '']" @click="changeSnipingMode('presales')" :key="reRenderSettings">&nbsp;PRESALES&nbsp;</span>
                                                  <span id="backLogsLaunches" v-bind:class="[defaultSettingsSelectableButtonClass, classBLLaunches, !sniperSettings.presales ? activeWalletClass : '']" @click="changeSnipingMode('launches')" :key="reRenderSettings">&nbsp;LAUNCHES&nbsp;</span>
                                                </span>
                                              </p>
                                              <span v-if="sniperSettings.presales != null && !sniperSettings.presales">
                                                <p class="el-st9 backButton">
                                                  <span class="el-st3 inputLabel selectableSpan selectableSpanText walletPKImport">Launches settings:&nbsp;</span>
                                                </p>
                                                <p class="walletRowP">
                                                  <span class="walletSpanAddress walletSpanAddressLaunches">oPTION</span>
                                                  <span class="walletSpanBNB walletSpanBNBLaunches">Use</span>
                                                </p>
                                                <p class="walletRowP">
                                                  <span class="walletSpanAddress walletSpanAddressLaunches">frontrun transactions</span>
                                                  <span v-bind:class="[defaultButtonWalletClass, sniperSettings.frontrun ? activeWalletClass : '']" @click="changeSnipingMode('frontrun')" :key="reRenderSettings">&nbsp;</span>
                                                </p>
                                                <p class="walletRowP">
                                                  <span class="walletSpanAddress walletSpanAddressLaunches">Wait for tradable</span>
                                                  <span v-bind:class="[defaultButtonWalletClass, sniperSettings.waitForTradable ? activeWalletClass : '']" @click="changeSnipingMode('waitForTradable')" :key="reRenderSettings">&nbsp;</span>
                                                </p>
                                                <p class="walletRowP">
                                                  <span class="walletSpanAddress walletSpanAddressLaunches">USE antibot delay</span>
                                                  <span v-bind:class="[defaultButtonWalletClass, sniperSettings.useAntiBotDelay ? activeWalletClass : '']" @click="changeSnipingMode('useAntiBotDelay')" :key="reRenderSettings">&nbsp;</span>
                                                </p>
                                                <p class="walletRowP"  v-if="sniperSettings.useAntiBotDelay">
                                                  <span class="walletInputHolder walletInputHolderLaunches">
                                                    <input type="text" class="input inputWallet inputAntibotDelay">
                                                    <span id="backLogsAntibotDelayBlocks" v-bind:class="[defaultSettingsSelectableButtonClass, classBLLaunches, sniperSettings.antibotBlocks ? activeWalletClass : '']" @click="changeSnipingMode('antibotBlocks')" :key="reRenderSettings">&nbsp;Blocks&nbsp;</span>
                                                    <span id="backLogsAntibotDelaySeconds" v-bind:class="[defaultSettingsSelectableButtonClass, classBLLaunches, !sniperSettings.antibotBlocks ? activeWalletClass : '']" @click="changeSnipingMode('antibotBlocks')" :key="reRenderSettings">&nbsp;Seconds&nbsp;</span>
                                                  </span>
                                                </p>
                                              </span>
                                            </span>
                                            <p v-if="sniperSettings.presales == null && accessToSettings" class="walletRowP">CONNECT TO CHANGE SETTINGS.</p>
                                            <p v-if="sniperSettings.presales == null && !accessToSettings" class="walletRowP">THIS FEATURE IS IN CLOSED TESTING.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
      </div>
</template>

<script>
/* eslint-disable */
/* eslint-disable */
//                                            <p class="el-st9">Welcome in botz.cloud presale sniper open test!<br><br>PINKSALE/DXSALE/UNICRYPT/GEMPAD is supported! Launches are not supported yet!<br><br>Short user guide:<br><br>&nbsp;&gt; ADDRESS - presale address<br>&nbsp;&gt; BNB - Amount of your participation<br>&nbsp;&gt; gas - gas price for transaction<br>&nbsp;&gt; Start/stop button is launching bot process<br>&nbsp;&gt; Click to "WALLETS" and create or import wallet from which you want to snipe. You can use multiple wallets for sniping.<br><br>Thanks to all contributors, and please note that this is an open test, so some features may not work as expected. We strive to improve the product constantly. We appreciate your patience!<br><br><br><br></p>
//<p class="error">Servers is on maintenance. ETA 10-16 hours.</p>
import io from 'socket.io-client'
export default {
  name: 'App',
  data () {
    return {
      botOn: false,
      address: "",
      amount: 0.1,
      key: '',
      gas: 5,
      logs: true,
      socket: io(process.env.NODE_ENV === 'production' ? 'https://servermain.def.cafe:2053' : 'http://localhost:2053'),
      logsArray: [],
      logsClassArray: ['standard','warning','warning','error'],
      logsClass: [],
      logsTxHasArray: [],
      block: 0,
      importWalletShow: false,
      showWalletWarning: false,
      walletWarningMessage: '',
      walletsArray : [],
      deletedWalletsExists : false,
      defaultButtonWalletClass: 'backLogs walletSpanUse',
      defaultButtonWalletImportClass: 'backLogs backLogsMargin',
      activeWalletClass: 'backLogButtonActive',
      defaultTrashClass: 'far fa-trash-alt trashIcon',
      activeTrashClass: 'fas fa-arrow-left trashIcon',
      showTrash: true,
      inputImportKey : '',
      walletWarningTimeout : null,
      blockWarningTimeout : null,
      settingsWarningTimeout : null,
      reRender: 0,
      reRenderUse: 0,
      showStars: [
        false,
        false,
        false,
        false,
        false,
        -1
      ],
      showStarsInterval: null,
      showStarsReRender1: 0,
      showStarsReRender2: 0,
      showStarsReRender3: 0,
      showStarsReRender4: 0,
      showStarsReRender5: 0,
      sniperSettings: {},
      tempSniperSettings: {},
      warningSettingsMessage: '',
      classMarginLeft : 'DisableLeftMargin',
      defaultSettingsSelectableButtonClass: 'backLogs backLogsSettingsOk',
      classBLLaunches: 'backLogsLaunches',
      reRenderSettings: 0, 
      accessToSettings: true,
      blockWarningMessage: '',
      currentScreen: 'logs',
      walletForDeletion: null,
      maintenance: false
    }
  },
  computed: {
    computedLogs () {
      return this.logsArray
    },
    computedTxHash () {
      return this.logsTxHasArray
    },
    computedBotOn () {
      return this.botOn
    },
    computedShowWalletWarning () {
      return this.showWalletWarning
    },
    computedShowImportWallet () {
      return this.importWalletShow
    },
    computedWallets() {
      return this.walletsArray
    },
    computedShowCreateButton() {
      let resultCreate = 0
      this.walletsArray.forEach((item, index) => {
          if(!item.imported && !item.deleted) resultCreate++
      })
      //if(this.walletsArray.length == 0) resultCreate = 3
      return resultCreate>=3?false:true;
    },
    computedShowImportButton() {
      let resultImport = 0
      this.walletsArray.forEach((item, index) => {
          if(item.imported && !item.deleted) resultImport++
      })
      //if(this.walletsArray.length == 0) resultImport = 3
      if(resultImport >=3) this.importWalletShow = false;
      return resultImport>=3?false:true;
    },
    computedShowBlockWarning() {
      const show = this.blockWarningMessage.length > 0? true : false;
      return show;
    },
    computedShowTrashClass() {
      return this.showTrash;
    },
    computedMaintenance() {
      return this.maintenance;
    }
  },
  methods: {
    start () {
      this.socket.emit(
        'start',
        this.address,
        this.amount,
        this.key,
        true,
        this.gas
      )
    },
    stop () {
      this.socket.emit('stop')
    },
    generateNonce(addr) {
      this.socket.emit('generateNonce', addr)
    },
    authenticateWithSign(addr, nonce, sign) {
      this.socket.emit('authenticateWithSign', addr,nonce,sign)
    },
    disconnect() {
      this.socket.emit('disconnectAuth')
    },
    updateBlock() {
      this.socket.emit('updateBlock');
    },
    updateWalletUse(index) {
      this.socket.emit('updateWalletUse',index)
      this.reRenderUse += 1;
    },
    createWallet() {
      this.socket.emit('createWallet')
    },
    importWallet() {
      this.socket.emit('importWallet',this.inputImportKey)
      this.showImportField()
    },
    showImportField() {
      this.importWalletShow = !this.importWalletShow;
      this.inputImportKey = ''
    },
    copyToClipboard(textToCopy) {
      let textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      // make the textarea out of viewport
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((res, rej) => {
          document.execCommand('copy') ? res() : rej();
          textArea.remove();
      }); 
    },
    async copyAddress(walAddr) {
      this.copyToClipboard(walAddr).then(() => { this.alertWallet('Wallet address copied to clipboard.', 1000)}).catch((e) => {this.alertWallet('Cannot copy wallet address.' + e, 1000)});
    },
    async copyKey(pkey) {
      this.copyToClipboard(pkey).then(() => { this.alertWallet('Private key copied to clipboard.', 1000)}).catch((e) => {this.alertWallet('Cannot copy private key.' + e, 1000)});
    },
    async copyTxHash(hash) {
      this.copyToClipboard(hash).then(() => { this.alertBlock('txHash copied to clipboard.', 1000)}).catch((e) => {this.alertBlock('Cannot copy txhash.' + e, 1000)});
    },
    alertWallet(message, time) {
      if(this.walletWarningTimeout) clearTimeout(this.walletWarningTimeout);
      this.walletWarningMessage = message;
      this.walletWarningTimeout = setTimeout(this.clearWalletMessage, time);
    },
    alertSettings(message, time) {
      if(this.settingsWarningTimeout) clearTimeout(this.settingsWarningTimeout);
      this.warningSettingsMessage = message;
      this.settingsWarningTimeout = setTimeout(this.clearWalletMessage, time);
    },
    alertBlock(message, time) {
      if(this.blockWarningTimeout) clearTimeout(this.blockWarningTimeout);
      this.blockWarningMessage = message;
      this.blockWarningTimeout = setTimeout(this.clearWalletMessage, time);
    },
    clearWalletMessage() {
      this.walletWarningMessage = '';
      this.warningSettingsMessage = '';
      this.blockWarningMessage = '';
    },
    getWalletsArr() {
      return this.walletsArray
    },
    updateWalletBalanceAtIndex(index,balance) {
      this.walletsArray[index].balance = balance
      this.reRender += 1
    },
    animateStars(enable) {
      let rnd = -2;
      do {
        rnd = Math.floor(Math.random() * (4 - 0 + 1) + 0)
      } while (rnd==this.showStars[5])
      this.showStars[5] = rnd
      this.showStars[rnd] = !this.showStars[rnd]
      
      switch(rnd) {
        case 0:
          this.showStarsReRender1 += 1
        break;
        case 1:
          this.showStarsReRender2 += 1
        break;
        case 2:
          this.showStarsReRender3 += 1
        break;
        case 3:
          this.showStarsReRender4 += 1
        break;
        case 4:
          this.showStarsReRender5 += 1
        break;
      }

    },
    showStarsAnim(enable) {
      if(enable) {
        if(this.showStarsInterval) clearInterval(this.showStarsInterval)
        this.showStarsInterval = setInterval(this.animateStars, 500, enable);
      } else {
        if(this.showStarsInterval) clearInterval(this.showStarsInterval)
        this.showStars = [
          false,
          false,
          false,
          false,
          false,
          -1
        ]
      }
    },
    changeSnipingMode(mode) {
      switch(mode) {
        case 'presales':
          this.tempSniperSettings = this.sniperSettings
          this.tempSniperSettings.presales = true; //SERVER
          this.socket.emit('updateUserSettings',this.tempSniperSettings)
          this.reRenderSettings += 1
        break;
        case 'launches':
          this.tempSniperSettings = this.sniperSettings
          this.tempSniperSettings.presales = false; //SERVER
          this.socket.emit('updateUserSettings',this.tempSniperSettings)
          this.reRenderSettings += 1
        break;
        case 'frontrun':
          this.tempSniperSettings = this.sniperSettings
          this.tempSniperSettings.frontrun = !this.tempSniperSettings.frontrun; //SERVER
          this.socket.emit('updateUserSettings',this.tempSniperSettings)
          this.reRenderSettings += 1
        break;
        case 'waitForTradable':
          this.tempSniperSettings = this.sniperSettings
          this.tempSniperSettings.waitForTradable = !this.tempSniperSettings.waitForTradable; //SERVER
          this.socket.emit('updateUserSettings',this.tempSniperSettings)
          this.reRenderSettings += 1
        break;
        case 'useAntiBotDelay':
          this.tempSniperSettings = this.sniperSettings
          this.tempSniperSettings.useAntiBotDelay = !this.tempSniperSettings.useAntiBotDelay; //SERVER
          this.socket.emit('updateUserSettings',this.tempSniperSettings)
          this.reRenderSettings += 1
        break;
        case 'antibotBlocks':
          this.tempSniperSettings = this.sniperSettings
          this.tempSniperSettings.antibotBlocks = !this.tempSniperSettings.antibotBlocks; //SERVER
          this.socket.emit('updateUserSettings',this.tempSniperSettings)
          this.reRenderSettings += 1
        break;
        
      }
    },
    showLogSection(section) {
      return this.currentScreen == section ? true : false;
    },
    showWalletRow(index) {
      let show = false;
      if(this.walletsArray.length) {
        if(this.walletsArray[index].deleted && !this.computedShowTrashClass) {
          show = true;
        }
        if(this.walletsArray[index].deleted && this.computedShowTrashClass) {
          show = false;
        }
        if(!this.walletsArray[index].deleted && this.computedShowTrashClass) {
          show = true;
        }
        if(!this.walletsArray[index].deleted && !this.computedShowTrashClass) {
          show = false;
        }
      }
      return show;
    },
    switchLogSection(section) {
      this.currentScreen = section;
      if(section == 'logs') {
        const logsDiv = document.getElementsByClassName('logs')
        this.$nextTick(() => {
        logsDiv[0].scrollTop = logsDiv[0].scrollHeight
      })
      }
    },
    confirmDeleteWallet(index) {
      this.walletForDeletion = index;
      this.currentScreen = 'popup';
    },
    cancelDeleteWallet() {
      this.walletForDeletion = null;
      this.currentScreen = 'wallets';
    },
    deleteWallet() {
      this.socket.emit('deleteWallet',this.walletForDeletion);
      this.currentScreen = 'wallets';
    },
    switchWalletView() {
      if(this.deletedWalletsExists) {
        this.showTrash = !this.showTrash;
      } else {
        this.alertWallet("You haven't deleted wallets yet, nothing to show.", 2000);
      }
    },
    showEmpty() {
      return ((!this.walletsArray.length) || this.computedShowTrashClass && !this.walletsArray.filter(item => (!item.deleted)).length); 
    }
  },
  created () {
    vm.component = this
    this.socket.on('log', (data) => {
      this.logsArray.push(data)
      this.logsTxHasArray.push(null)
      this.logsClass.push(this.logsClassArray[0])
      const logsDiv = document.getElementsByClassName('logs')
      this.$nextTick(() => {
        logsDiv[0].scrollTop = logsDiv[0].scrollHeight
      })
    })
    this.socket.on('logSpecial', ({msg, cls}) => {
      const logsDiv = document.getElementsByClassName('logs')
      let txhash = null;
      if(cls==3) { //error(for tx hash)
       if(msg.includes('Transaction hash:')) {
          txhash = msg.substring(msg.indexOf('Transaction hash:')+18, msg.length-1);
          msg = msg.substring(0, msg.indexOf('Transaction hash:')+18);
        }
      }
      this.logsArray.push(msg)
      this.logsTxHasArray.push(txhash)
      this.logsClass.push(this.logsClassArray[cls])
      this.$nextTick(() => {
        logsDiv[0].scrollTop = logsDiv[0].scrollHeight
      })
    })
    this.socket.on('checkBot', (data) => {
      this.botOn = data
      this.showStarsAnim(data)
    })
    this.socket.on('nonce', (data) => {
      AuthenticateWithWallet(data);
    })
    this.socket.on('onDisconnect', () => {
      this.walletsArray = []
      this.deletedWalletsExists = false;
      this.sniperSettings = {}
      this.accessToSettings = true;
      this.socket.disconnect()
      this.socket.connect()
    })
    this.socket.on('onBlockUpdate', (data) => {
      this.block = data;
    })
    this.socket.on('onWalletsUpdate', (data) => {
      this.walletsArray = data;
      for(let i = 0; i < data.length; i++) {
        if(data[i].deleted) {
          this.deletedWalletsExists = true;
          break;
        }
      }
    })
    this.socket.on('onWalletAlert', (data) => {
      this.alertWallet(data, 3000);
    })
    this.socket.on('onSettingsChanged', (data) => {
      if(data.enabled) {
        this.sniperSettings = data.settings;
      } else {
        this.sniperSettings = {};
        this.accessToSettings = false;
      }
      
    })
    this.socket.on('onSettingsAlert', (data) => {
      this.alertSettings(data, 3000);
    })
    this.socket.on('onGetStatus', (data) => {
    if(data) {
        if(data.maintenance) {
          this.maintenance = true;
        } else {
          this.maintenance = false;
        }
      } else {
        this.maintenance = false;
      }
    })
    this.socket.emit('getStatus')
  }
}
</script>

<style>

</style>
